/* eslint-disable @typescript-eslint/naming-convention */

export interface ResendUserWelcomeMessageData {
  schoolId: string;
  uid: string;
}

export interface GetLastUserMessagesSentData {
  schoolId: string;
  uid: string;
}

export enum MessageStatus {
  UNKNOWN = 'unknown',
  QUEUED = 'queued',
  SENT = 'sent',
  OPENED = 'opened',
  CLICKED = 'clicked',
  BOUNCE = 'bounce',
  SPAM = 'spam',
  UNSUB = 'unsub',
  BLOCKED = 'blocked',
  HARDBOUNCED = 'hardbounced',
  SOFTBOUNCED = 'softbounced',
  DEFERRED = 'deferred',
  BadEmailAddress = 'BadEmailAddress',
  Delayed = 'transient',
}

export interface GetLastUserMessagesSentReturnData {
  messages: MailjetMessage[];
}

export interface MailjetMessageServerResponse {
  ID: string;
  ArrivedAt: string;
  Subject: string;
  Status: MessageStatus | string;
}

export interface MailjetMessage extends MailjetMessageServerResponse {
  id: string;
  uid: string;
}
