import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MessageStatusIconDirective } from './directives/message-status-icon/message-status-icon.directive';
import { MessageStatusPipe } from './message-status/message-status.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [MessageStatusIconDirective, MessageStatusPipe],
  exports: [MessageStatusIconDirective, MessageStatusPipe],
})
export class AdminSharedUiMessageStatusModule {}
