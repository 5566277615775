import { Pipe, PipeTransform } from '@angular/core';
import { MessageStatus } from '@memberspot/models';

const status: Record<MessageStatus, string> = {
  [MessageStatus.BLOCKED]: 'blocked',
  [MessageStatus.BOUNCE]: 'bounce',
  [MessageStatus.CLICKED]: 'clicked',

  [MessageStatus.DEFERRED]: 'defered',
  [MessageStatus.HARDBOUNCED]: 'hardBounced',
  [MessageStatus.OPENED]: 'opened',
  [MessageStatus.QUEUED]: 'queued',

  [MessageStatus.SENT]: 'sent',
  [MessageStatus.SOFTBOUNCED]: 'softBounced',
  [MessageStatus.Delayed]: 'delayed',
  [MessageStatus.BadEmailAddress]: 'badEmailAddress',
  [MessageStatus.SPAM]: 'spam',
  [MessageStatus.UNKNOWN]: 'unknown',
  [MessageStatus.UNSUB]: 'unsub',
};

@Pipe({
  name: 'messageStatus',
})
export class MessageStatusPipe implements PipeTransform {
  transform(value: MessageStatus) {
    return `admin.users.messageSendStatus.${status[value]}`;
  }
}
