import { Directive, HostBinding, Input } from '@angular/core';
import { MessageStatus } from '@memberspot/models';

const colors: Record<MessageStatus, string> = {
  [MessageStatus.BLOCKED]: '#ffbd39',
  [MessageStatus.BOUNCE]: '#ffbd39',
  [MessageStatus.CLICKED]: '#56a003',

  [MessageStatus.DEFERRED]: 'grey',
  [MessageStatus.HARDBOUNCED]: '#ffbd39',
  [MessageStatus.OPENED]: '#22c55e',
  [MessageStatus.QUEUED]: 'white',

  [MessageStatus.SENT]: '#22c55e',
  [MessageStatus.SOFTBOUNCED]: 'grey',
  [MessageStatus.Delayed]: 'grey',
  [MessageStatus.BadEmailAddress]: 'red',
  [MessageStatus.SPAM]: 'red',
  [MessageStatus.UNKNOWN]: 'grey',
  [MessageStatus.UNSUB]: '#ffbd39',
};

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[appMessageStatusIcon]',
})
export class MessageStatusIconDirective {
  private _color!: string;

  @Input('appMessageStatusIcon')
  set statusColor(status: MessageStatus) {
    this._color = colors[status];
  }

  @HostBinding('style.background')
  get color() {
    return this._color;
  }
}
